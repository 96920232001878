import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { shuffle } from "@/common/lib/arrays";
import MnemonicInput from "./MnemonicInput";
import LandingPagePopUp from "@/views/LandingPage/LandingPagePopUp.vue";
import errorMessage from "@/common/constants/error-messages";
export default {
  name: "GenerateAccount",
  components: {
    LandingPagePopUp,
    MnemonicInput
  },
  data: () => ({
    mnemonicCollection: [],
    mnemonicInputs: [],
    mnemonicValid: false,
    isIncorrect: false,
    error: null
  }),
  mounted() {
    this.mnemonicCollection.push(...this.$route.params.mnemonicCollection);
    const mnemonicCollection = shuffle(this.$route.params.mnemonicCollection);
    this.mnemonicInputs.push(...mnemonicCollection);
  },
  methods: {
    previous() {
      this.$router.push({
        name: "generate-mnemonic"
      });
    },
    setPassword() {
      this.$router.push({
        name: "set-password",
        params: {
          mnemonic: this.$route.params.mnemonic
        }
      });
    },
    validate(inputs) {
      this.mnemonicValid = false;
      if (inputs.length < this.mnemonicCollection.length) {
        this.isIncorrect = false;
        this.error = null;
        return;
      }
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i] != this.mnemonicCollection[i]) {
          this.isIncorrect = true;
          this.error = errorMessage.INCORRECT("Mnemonic");
          return;
        }
      }
      this.mnemonicValid = true;
    }
  }
};