//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { chunk } from "@/common/lib/arrays";
export default {
  name: "MnemonicKeypad",
  props: {
    mnemonicCollection: Array
  },
  computed: {
    chunkedMnemonic() {
      return chunk(this.mnemonicCollection, 6);
    }
  },
  methods: {
    clickMnemonic(mnemonic) {
      const index = this.mnemonicCollection.indexOf(mnemonic);
      this.mnemonicCollection.splice(index, 1);
      this.$emit("click", mnemonic);
    }
  }
};