//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { chunk } from "@/common/lib/arrays";
import MnemonicKeypad from "./MnemonicKeypad.vue";
export default {
  name: "MnemonicInput",
  components: {
    MnemonicKeypad
  },
  props: {
    mnemonicCollection: Array
  },
  data: () => ({
    mnemonicInput: []
  }),
  computed: {
    chunkedMnemonic() {
      return chunk(this.mnemonicInput, 6);
    }
  },
  methods: {
    input(mnemonic) {
      this.mnemonicInput.push(mnemonic);
      this.$emit("click", this.mnemonicInput);
    },
    remove(mnemonic) {
      const index = this.mnemonicInput.indexOf(mnemonic);
      if (index !== -1) {
        this.mnemonicInput.splice(index, 1);
      }
      this.mnemonicCollection.push(mnemonic);
      this.$emit("click", this.mnemonicInput);
    }
  }
};